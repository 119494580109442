import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GradingIcon from '@mui/icons-material/Grading';
import RuleIcon from '@mui/icons-material/Rule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineIcon from '@mui/icons-material/Timeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';


import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AddchartIcon from '@mui/icons-material/Addchart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HistoryIcon from '@mui/icons-material/History';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/account-wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>  

              <Link  to='/send-coin' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Send`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/receive' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CurrencyExchangeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Receive`} />
                  </ListItemButton>
              </ListItem>
            </Link>           

              <Link  to='/plans' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <GradingIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Copy Trading`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/investments' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Copying History`} />
                  </ListItemButton>
              </ListItem>
            </Link>

              <Link  to='/spot-transfer' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <SwitchAccessShortcutIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Transfer`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/start-trading' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AddchartIcon />
                    
                    </ListItemIcon>
                    <ListItemText  primary={`Crypto Trading`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            <Link  to='/trade-forex' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AddchartIcon />
                    
                    </ListItemIcon>
                    <ListItemText  primary={`Forex Trading`} />
                    </ListItemButton>
                </ListItem>
              </Link>


              <Link  to='/trade-stock' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AddchartIcon />
                    
                    </ListItemIcon>
                    <ListItemText  primary={`Stock Trading`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              

              <Link  to='/trade-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CandlestickChartIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Live Trade History`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
            <Link  to='/markets' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <StackedBarChartIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Forex Markets`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/stock-markets' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <StackedBarChartIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Stock Markets`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/crypto-markets' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <StackedBarChartIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Crypto Markets`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link to='/investment-plans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <SmartToyIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Investment`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link to='/bots-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HistoryIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Investment History`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
            <Link  to='/swap-coin' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <SwapHorizIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Swap`} />
                    </ListItemButton>
                </ListItem>
              </Link>
              
            <Link  to='/transactions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Deposits`} />
                  </ListItemButton>
              </ListItem>
            </Link>
              <Link  to='/withdrawals' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Withdrawals`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/cards' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Cards`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link>

             {/* <Link  to='/kyc-verification' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <VerifiedUserIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`KYC Verification`} />
                  </ListItemButton>
              </ListItem>
            </Link> */}

            {/* <Link to='/investment-plans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CoPresentIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Shareholders`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/loans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RuleIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Loans`} />
                  </ListItemButton>
              </ListItem>
            </Link> */}
                     
          </List>
          <List>
            
            <Divider/>
            {/* <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Support`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  href='' underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <EmailIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Email Contact`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/settings' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}